// Emotion types
/// <reference types="@emotion/react/types/css-prop" />
import { css } from "@emotion/react";
import styled from "@emotion/styled";
// import { StaticImage } from "gatsby-plugin-image";

// Subtitles
// import campaignSubs from "file-loader!../assets/subtitles/campaign.vtt";

import React, { useRef, useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import { useMediaQuery } from "react-responsive";

// Images, previous flight
import datacenter from "../assets/images/datacenter.jpg";
// import eFerry from "../assets/images/e-ferry.jpg";
import transportTruck from "../assets/images/transport-truck.jpg";
import solar from "../assets/images/solar.jpg";
import wind from "../assets/images/wind.jpg";
import windFull from "../assets/images/wind2-full.jpg";
import hydrogen from "../assets/images/se-flight4-22/hydrogen-case.jpg";
import emobility from "../assets/images/emobility.jpg";
import steel from "../assets/images/se-flight5-23/steel-case.jpg";
import plastic from "../assets/images/se-flight5-23/plastic-case.jpg";
import aviation from "../assets/images/aviation.jpg";

// Videos
import cloudsMp4 from "../assets/videos/clouds.mp4";
import cloudsWebM from "../assets/videos/clouds.webm";
// import homeMp4 from "../assets/videos2/homeMp4.mp4";
// import homeMp4Mobile from "../assets/videos2/homeMp4Mobile.mp4";
// import homeWebM from "../assets/videos2/homeWebM.webm";
// import infoGraphicMp4 from "../assets/videos/se-flight5-23/infographic.mp4";
// import steamWebM from "../assets/videos/se-flight6-23/steam.webm";
// import foggyWindowMp4 from "../assets/videos/se-flight6-23/foggyWindow.mp4";
// import foggyWindowWebM from "../assets/videos/se-flight6-23/foggyWindow.webm";
import heroMp4Mobile from "../assets/videos/se-flight7-23/fossilFreeHeroMobile.mp4";
import heroMp4Desktop from "../assets/videos/se-flight7-23/fossilFreeHeroDesktop.mp4";
import heroWebMDesktop from "../assets/videos/se-flight7-23/fossilFreeHeroDesktop.webm";
import heroWebMMobile from "../assets/videos/se-flight7-23/fossilFreeHeroMobile.webm";

import {
    Button,
    Case,
    Footer,
    // Headline,
    HeroText,
    Hero,
    Layout,
    // MapImage,
    Metadata,
    Navigation,
    VideoHero,
    Quiz,
    // locationsSwedish,
    // CaseVideo,
    // FlyingHat,
} from "../components";

// Styling

// const SteamWrapper = styled.div`
//     width: 100%;
//     position: relative;
//     margin: 0 auto;
//     z-index: ${({ zIndex }: { zIndex: string }) => zIndex};

// `;

// const SteamVideo = styled.video`
//     position: absolute;
//     top: -50%;
//     left: 0px;
//     width: 100%;
//     z-index: 9999;

//     @media screen and (max-width: 768px) {
//         top: -100px;
//         width: 100%;
//     }
// `;

const StickyBtn = styled.a`
    position: sticky;
    top: 80px;
    z-index: 10;
`;

// const VideoWrapper = styled.div`
//     max-height: 600px;
//     overflow: hidden;
//     width: 100%;
//     @media screen and (max-width: 768px) {
//         max-height: 500px;
//         section > div > div {
//             max-height: 470px;
//         }
//     }
//     section {
//         //padding-bottom: 41.25%;
//         padding-bottom: 32.25%;
//     }
//     video {
//         object-fit: cover !important;
//         pointer-events: none !important;
//         object-position: bottom !important;
//     }
// `;

const WaypointWrapper = styled.div`
    margin: 0;
    span {
        margin: 0;
    }
`;

// let steamHasPlayed = false;

// Markup
export const Swedish = () => {
    const aviationRef = useRef<HTMLDivElement>(null);
    const steelRef = useRef<HTMLDivElement>(null);
    const roadRef = useRef<HTMLDivElement>(null);
    const emobilityRef = useRef<HTMLDivElement>(null);
    const datacenterRef = useRef<HTMLDivElement>(null);
    const oceanRef = useRef<HTMLDivElement>(null);
    const solarRef = useRef<HTMLDivElement>(null);
    const plasticRef = useRef<HTMLDivElement>(null);

    const [zoom, setZoom] = useState(4.2);

    // Waypoints
    // const [playInfo, setPlayInfo] = useState(false);
    // const [playSteam, setPlaySteam] = useState(true);
    // const [steamIsActive, setSteamIsActive] = useState(true);
    const [casePoint1, setcasePoint1] = useState(false);
    const [casePoint2, setcasePoint2] = useState(false);
    const [casePoint3, setcasePoint3] = useState(false);
    const [casePoint4, setcasePoint4] = useState(false);
    const [casePoint5, setcasePoint5] = useState(false);
    const [casePoint6, setcasePoint6] = useState(false);
    const [casePoint7, setcasePoint7] = useState(false);
    const [casePoint8, setcasePoint8] = useState(false);
    const [casePoint9, setcasePoint9] = useState(false);

    const offsetValue = "30%";

    useEffect(() => {
        window.innerWidth > 768 ? setZoom(4.2) : setZoom(3.75);
    }, [zoom]);

    // useLayoutEffect(() => {
    //     const steamTimeout = setTimeout(() => {
    //         setSteamIsActive(false);
    //     }, 6000);

    //     return () => {
    //         clearTimeout(steamTimeout);
    //     }
    // }, []);

    // const handleSteam = () => {
    //     if (playSteam) {
    //         setTimeout(() => {
    //             setPlaySteam(false);
    //             steamHasPlayed = true;
    //         }, 4500);
    //     }
    // }

    // NO MAP IN FLIGHT 6
    // Order important for scrollTo, same as array of map-markers
    // const articleRefs = [
    //     steelRef,
    //     aviationRef,
    //     solarRef,
    //     emobilityRef,
    //     roadRef,
    //     datacenterRef,
    //     plasticRef,
    //     oceanRef,
    // ];


    const isDesktop = useMediaQuery({
        query: "(min-width: 600px)",
    });

    const vwoScript = (url: string, selector = "head", async = true) => {
        useEffect(() => {
            const element = document.querySelector(selector);
            const script = document.createElement("script");
            script.type = "text/plain";
            script.dataset.cmpSrc = url;
            script.async = async;
            script.className = "cmplazyload";
            script.dataset.cmpVendor = "s31";
            element?.appendChild(script);
            return () => {
                element?.removeChild(script);
            };
        }, [url]);
    };

    vwoScript("./vwoSe.js");

    const gtmScript = (url: string, selector = "head", async = true) => {
        useEffect(() => {
            const element = document.querySelector(selector);
            const script = document.createElement("script");
            script.type = "text/plain";
            script.dataset.cmpSrc = url;
            script.async = async;
            script.className = "cmplazyload";
            script.dataset.cmpVendor = "s905";
            element?.appendChild(script);
            return () => {
                element?.removeChild(script);
            };
        }, [url]);
    };

    gtmScript("./gtmSe.js");

    // const autoblockScript = (url: string, selector = "body", async = true) => {
    //     useEffect(() => {
    //         const element = document.querySelector(selector);
    //         const script = document.createElement("script");
    //         script.src = url;
    //         script.async = async;
    //         script.dataset.cmpAb = "1";
    //         script.dataset.cmpCodesrc = "1";
    //         script.dataset.cmpHost = "a.delivery.consentmanager.net";
    //         script.dataset.cmpCdn = "cdn.consentmanager.net";
    //         element?.appendChild(script);
    //         return () => {
    //             element?.removeChild(script);
    //         };
    //     }, [url]);
    // };

    // autoblockScript(
    //     "https://cdn.consentmanager.net/delivery/autoblocking/8dfee79444b1.js"
    // );



        // autoblocking
        useEffect(() => {
            if (typeof window !== 'undefined') {
                const element = document.querySelector("body");
                const script = document.createElement("script");
                script.type = "text/javascript";
                // Get the hostname from the current URL
                const hostname = window.location.hostname;
        
                // Check if the hostname contains 'acc'
                if (hostname.includes('acc')) {
                    // Load consent manager script for 'acc' environment
                    script.src = "./consentManagerAccSe.js";
                } else {
                    // Default to loading consent manager script for production
                    script.src = "./consentManagerProdSe.js";
                }
                script.async = true;
                element?.appendChild(script);
                return () => {
                    element?.removeChild(script);
                };
            }
        }, []);

        //piwik
        useEffect(() => {
            if (typeof window !== 'undefined') {
                const element = document.querySelector("body");
                const script = document.createElement("script");
                script.type = "text/plain";
                script.className = "cmplazyload";
                script.dataset.cmpVendor = "c43757";
                // Get the hostname from the current URL
                const hostname = window.location.hostname;
        
                // Check if the hostname contains 'acc'
                if (hostname.includes('acc')) {
                    // Load piwik script for 'acc' environment
                    script.dataset.cmpSrc = "./piwikAccSe.js";
                } else {
                    // Default to loading piwik script for production
                    script.dataset.cmpSrc = "./piwikProdSe.js";
                }
                script.async = true;
                element?.appendChild(script);
                return () => {
                    element?.removeChild(script);
                };
            }
        }, []);

    return (
        <>
            <Metadata />
            <Navigation />
            <Layout>
                {/* <SteamWrapper zIndex={steamIsActive ? "99999" : "9"}> */}
                {isDesktop ? (
                    <VideoHero
                        primary
                        webM={heroWebMDesktop}
                        mp4={heroMp4Desktop}
                        // subtitles={campaignSubs}
                        subLang="sv"
                        subLabel="Svenska"
                    >
                        {/*<h1>Bli en del av bra saker för klimatet</h1>*/}
                    </VideoHero>
                ) : (
                    <VideoHero
                        primary
                        webM={heroWebMMobile}
                        mp4={heroMp4Mobile}
                        // subtitles={campaignSubs}
                        subLang="sv"
                        subLabel="Svenska"
                    >
                        {/*<h1>Bli en del av bra saker för klimatet</h1>*/}
                    </VideoHero>
                )}
                {/* {steamIsActive && (
                        <SteamVideo
                            autoPlay
                            muted
                            playsInline
                            disablePictureInPicture
                            src={steamWebM}
                        />
                    )} */}
                {/* </SteamWrapper> */}

                <StickyBtn
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.vattenfall.se/elavtal/?icmp=elkampanjflight5_sticky"
                >
                    <Button intent="primary">Hitta ditt fossilfria elavtal</Button>
                </StickyBtn>

                {/* <VideoWrapper>
                    <VideoHero
                        primary
                        webM={foggyWindowWebM}
                        mp4={foggyWindowMp4}
                    >
                        <div
                            style={{
                                height: "570px",
                                position: "absolute",
                                display: "flex",
                                alignItems: "center",
                                top: 0,
                            }}
                        >
                            <HeroText color="#fff">
                                Bli en del av bra saker. Som vårt arbete för ett
                                fossilfritt liv imorgon. Och smart
                                energianvändning idag.
                            </HeroText>
                        </div>
                    </VideoHero>
                </VideoWrapper> */}
                <Hero backgroundImage={windFull}>

                    <div
                        css={css`
                            max-width: 705px;

                            @media screen and (max-width: 480px) {
                                max-width: 320px;
                              }
                          
                        `}
                    >
                        <HeroText color="#fff">
                            Bli en del av fossilfri plast, renare industriutsläpp, mer kraft från vinden och hus som är naturliga kraftstationer med ditt elavtal.
                        </HeroText>
                    </div>
                </Hero>

                {/* 
                <div style={{ maxWidth: "820px", textAlign: "center" }}>
                    <h2>Fossilfri vätgas till industrin</h2>

                    <div style={{ marginTop: "35px" }}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://group.vattenfall.com/se/nyheter-och-press/pressmeddelanden/2022/varldens-forsta-vatgasproducerande-havsbaserade-vindkraftverk-far-93-miljoner-gbp-i-innovationsstod?_t_id=Z2ZORuUylwiRcnEQZ4Hd8g%3d%3d&_t_uuid=ME%2fz2mo6Q86zGBhOFPgoVQ&_t_q=aberdeen&_t_tags=language%3asv%2csiteid%3a00e53530-5acd-4b18-95ba-5eb803679d87%2candquerymatch&_t_hit.id=Corporate_Web_Cms_ContentTypes_Pages_PressReleasePage/_effca6dd-5486-4234-938c-0cf423e33cc2_sv&_t_hit.pos=1"
                        >
                            <Button intent="primary">Läs mer</Button>
                        </a>
                    </div>
                </div> */}

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setcasePoint1(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>

                <Case
                    inView={casePoint1}
                    ref={plasticRef}
                    image={plastic}
                    imageOn="left"
                    offset={50}
                    backgroundColor="#EDF1F6"
                >
                    <h4>Fossilfri plast</h4>
                    <p>
                        Tillverka plast utan en droppe olja? Den tekniken
                        utforskar vi just nu. Med hjälp av en process som
                        använder infångad koldioxid och fossilfri vätgas för att
                        producera metanol.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/fokus/trender-och-innovation/fossilfri-plast/?icmp=elkampanjflight5_plast"
                    >
                        Läs mer om fossilfri plast
                    </a>
                </Case>

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => {
                            setcasePoint2(true);
                            // setPlaySteam(true);
                            // handleSteam();
                        }}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>

                {/* <SteamWrapper zIndex="9"> */}
                <Case
                    inView={casePoint2}
                    ref={oceanRef}
                    image={hydrogen}
                    imageOn="right"
                    offset={50}
                    backgroundColor="#EDF1F6"
                >
                    <h4>Renare utsläpp</h4>
                    <p>
                        Renare utsläpp är framtiden. Vi arbetar med att
                        ersätta fossila bränslen som gas och olja i
                        industrin med fossilfri vätgas. Allt som behövs för
                        framställningen är elektricitet och vatten.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/fokus/trender-och-innovation/vatgas/?icmp=elkampanjflight5_vatgas"
                    >
                        Läs mer om fossilfri vätgas
                    </a>
                </Case>
                {/* {playSteam && !steamHasPlayed && (
                        <SteamVideo
                            autoPlay
                            muted
                            playsInline
                            disablePictureInPicture
                            src={steamWebM}
                        />
                    )} */}
                {/* </SteamWrapper> */}

                <Quiz />

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setcasePoint3(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>

                <Case
                    inView={casePoint3}
                    ref={oceanRef}
                    image={wind}
                    imageOn="right"
                    offset={50}
                    backgroundColor="#EDF1F6"
                >
                    <h4>Fri kraft från vinden</h4>
                    <p>
                        Det blåser mycket till havs. Och det pressar inte bara
                        ner elpriserna. Nästa stora utvecklingssteg inom
                        vindkraft är flytande vindkraftsparker som kan producera
                        fossilfri vätgas.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/fokus/hallbarhet/vindkraft-for-fossilfri-framtid/?icmp=elkampanjflight5_vind"
                    >
                        Läs mer om fossilfri vindkraft
                    </a>
                </Case>

                {/* INGEN CASEVIDEO I FLIGHT 6 */}

                {/* <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setPlayInfo(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>

                <CaseVideo
                    startVideo={playInfo}
                    video={infoGraphicMp4}
                    backgroundColor="#fff"
                    textWidth="440px"
                    headline="Smart energianvändning"
                    quoteText="Ett sätt att dämpa kostnadstopparna för elen där hemma är att optimera din förbrukning och minska spill. Våra energirådgivare hjälper dig.
                    "
                    // quoteText="Head of Market Development & Wind Farm Design"
                ></CaseVideo> */}

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setcasePoint4(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>

                <Case
                    inView={casePoint4}
                    ref={steelRef}
                    image={steel}
                    imageOn="left"
                    offset={50}
                    backgroundColor="#EDF1F6"
                >
                    <h4>Fossilfritt stål</h4>
                    <p>
                        Mänsklighetens mest värdefulla metall kan minska
                        världens koldioxidutsläpp med 7%. Vårt samarbetsprojekt
                        för att utveckla fossilfri ståltillverkning är redan
                        igång.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/fokus/trender-och-innovation/hybrit-fossilfri-stalproduktion/?icmp=elkampanjflight5_stal"
                    >
                        Läs mer om fossilfritt stål
                    </a>
                </Case>

                {/* <Headline
                    primary="Bra saker på väg"
                    secondary="Upptäck några exempel på utvecklingen du blir en del av."
                /> */}

                {/* INGEN KARTA I FLIGHT 6 */}

                {/* <MapImage articleRefs={articleRefs} data={locationsSwedish} /> */}

                {/* <div ref={aviationRef}>
                    <StaticImage
                        src="../assets/images/aviation-full.jpg"
                        alt="aviation"
                        style={{ width: "100%" }}
                    />
                </div>

                <div style={{ maxWidth: "820px", textAlign: "center" }}>
                    <h2>Utvecklingen av hållbart flygbränsle har börjat</h2>

                    <div style={{ marginTop: "35px" }}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.vattenfall.se/fokus/trender-och-innovation/fossilfritt-flyg/"
                        >
                            <Button intent="primary">Läs mer</Button>
                        </a>
                    </div>
                </div> */}

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setcasePoint5(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>

                <Case
                    inView={casePoint5}
                    ref={aviationRef}
                    image={aviation}
                    imageOn="right"
                    offset={50}
                    backgroundColor="#F2F2F2"
                >
                    <h4>Mer hållbart flygbränsle</h4>
                    <p>
                        Går det att göra flygresor mer hållbara? Vi tror det. Vi
                        undersöker hur elektrobränsle med fossilfri el, vatten
                        och infångad koldioxid kan bli ett alternativ.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/fokus/trender-och-innovation/fossilfritt-flyg/?icmp=elkampanjflight5_flyg"
                    >
                        Läs mer om hållbart flygbränsle
                    </a>
                </Case>

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setcasePoint6(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>

                <Case
                    inView={casePoint6}
                    ref={roadRef}
                    image={transportTruck}
                    imageOn="left"
                    offset={50}
                    backgroundColor="#F2F2F2"
                >
                    <h4>Trådlösa elvägar</h4>
                    <p>
                        Världens första trådlösa elväg för tunga lastbilar och
                        bussar är invigd. Det går nu att köra tunga transporter
                        mellan flygplatsen och Visby helt på el.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/fokus/eldrivna-transporter/elektriska-vagar/?icmp=elkampanjflight5_vagar"
                    >
                        Läs mer om trådlösa elvägar
                    </a>
                </Case>

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setcasePoint7(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>
                <Case
                    inView={casePoint7}
                    ref={datacenterRef}
                    image={datacenter}
                    imageOn="right"
                    offset={50}
                    backgroundColor="#F2F2F2"
                >
                    <h4>Fossilfria datacenter</h4>
                    <p>
                        Allt fler globala leverantörer av digitala tjänster
                        väljer att placera sina datacenter i norra Sverige, i
                        serverhallar som drivs av fossilfri el.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/fokus/hallbarhet/fossilfria-datacenter/?icmp=elkampanjflight5_datacenter"
                    >
                        Läs mer om fossilfria datacenter
                    </a>
                </Case>

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setcasePoint8(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>
                <Case
                    inView={casePoint8}
                    ref={emobilityRef}
                    image={emobility}
                    imageOn="left"
                    offset={50}
                    backgroundColor="#EDF1F6"
                >
                    <h4>Fler laddstationer</h4>
                    <p>
                        Räckviddsfunderingar blir en allt mindre fråga. Vårt
                        nätverk av snabbladdare längs vägen växer i takt med att
                        allt fler fordon drivs av el.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/fokus/eldrivna-transporter/framtidens-bilkorning/?icmp=elkampanjflight5_laddnatverk"
                    >
                        Läs mer om fossilfria laddnätverk
                    </a>
                </Case>

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setcasePoint9(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>

                <Case
                    inView={casePoint9}
                    ref={solarRef}
                    image={solar}
                    imageOn="right"
                    offset={50}
                    backgroundColor="#F2F2F2"
                >
                    <h4>Naturliga kraftstationer</h4>
                    <p>
                        För varje år, växer kapaciteten att ta vara på den fria
                        energin från solen. Egenproducerad el kommer från allt
                        fler villor och fastigheter.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/fokus/solceller/ekohuset-solenergi/?icmp=elkampanjflight5_solenergi"
                    >
                        Läs mer om fossilfri solenergi
                    </a>
                    {/*<StandOutImage src={vfSun} />*/}
                </Case>
                {/* <Quote
                    backgroundColor="#fff0ea"
                    textWidth="630px"
                    text="Vi samarbetar med många fastighetsägare för att hjälpa till att skapa laddinfrastruktur på plats."
                    quoteName="Annika Ramsköld,"
                    quoteText="Vattenfalls hållbarhetschef"
                ></Quote> */}

                {/* <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setcasePoint8(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>
                <Case
                    inView={casePoint8}
                    ref={ferryRef}
                    image={eFerry}
                    imageOn="left"
                    offset={50}
                    backgroundColor="#EDF1F6"
                >
                    <h4>Eldrivna färjor</h4>
                    <p>
                        Sedan ett par år trafikerar elhybridfärjan E/S Movitz
                        Mälaren i Stockholm. Färjan tar tio minuter att ladda
                        för en timmes trafik.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/fokus/eldrivna-transporter/elfarjan-es-movitz/"
                    >
                        Läs mer
                    </a>
                </Case> */}

                {/* <div ref={aviationRef}>
                    <StaticImage
                        src="../assets/images/aviation-full.jpg"
                        alt="aviation"
                        style={{ width: "100%" }}
                    />
                </div> */}

                {/* <div style={{ maxWidth: "820px", textAlign: "center" }}>
                    <h2>Utvecklingen av hållbart flygbränsle har börjat</h2>

                    <div style={{ marginTop: "35px" }}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.vattenfall.se/fokus/trender-och-innovation/fossilfritt-flyg/"
                        >
                            <Button intent="primary">Läs mer</Button>
                        </a>
                    </div>
                </div> */}
                {/* Testkommentar */}
                <VideoHero webM={cloudsWebM} mp4={cloudsMp4} minHeight>
                    <h2>Förnybar eller fossilfri el?</h2>
                    <div
                        css={css`
                            max-width: 705px;
                        `}
                    >
                        <HeroText small color="#fff">
                            All el vi säljer i Sverige är fossilfri. Det innebär
                            att elen produceras i princip helt utan utsläpp av
                            koldioxid. Vissa energislag är dessutom förnybara.
                            Vattenkraft, solkraft och vindkraft produceras med
                            energikällor som inte tar slut. Kärnkraft produceras
                            av uran, som inte är en outsinlig källa även om
                            energin som utvinns är mycket effektiv.
                        </HeroText>
                    </div>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/elavtal/energikallor/?icmp=elkampanjflight5_energikallor"
                    >
                        <Button>Läs mer om energikällorna</Button>
                    </a>
                </VideoHero>
            </Layout>
            <Footer />
        </>
    );
};
